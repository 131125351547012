@import '../../styles/variables.less';

.register-container {
  min-height: calc(100vh - 70px) !important;
  .ant-layout-content {
    margin-top: 40px;
    margin-bottom: 100px;
  }
  .ant-layout-sider {
    background-size: size !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: auto 100% !important;
  }
  .content-title {
    margin-top: 40px;
  }
  form {
    .ant-form-item {
      padding-top: 10px;
      margin-bottom: 10px;
    }
    .ant-btn {
      margin-top: 20px;
      width: 100%;
    }
  }
}

@primary-color: #66FF66;@error-color: #FF6666;@highlight-color: #FF6666;@btn-primary-color: #1E2319;@font-family: Roboto Mono, monospace;@code-family: Roboto Mono, monospace;@font-size-base: 16px;@font-size-sm: 14px;@menu-bg: #1E2319;@layout-body-background: #101010;@layout-footer-background: rgba(30, 35, 25, 0.9);@layout-header-background: rgba(30, 35, 25, 0.9);@layout-trigger-color: #66FF66;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;