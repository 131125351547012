@import '../../styles/variables.less';

.dashboard-layout-content {
    text-align: center;

    img {
        margin-top: 20px;
        max-width: 60%;

        @media screen and (max-width: @mobile-width) {
            max-width: 90%;
        }
    }
}
@primary-color: #66FF66;@error-color: #FF6666;@highlight-color: #FF6666;@btn-primary-color: #1E2319;@font-family: Roboto Mono, monospace;@code-family: Roboto Mono, monospace;@font-size-base: 16px;@font-size-sm: 14px;@menu-bg: #1E2319;@layout-body-background: #101010;@layout-footer-background: rgba(30, 35, 25, 0.9);@layout-header-background: rgba(30, 35, 25, 0.9);@layout-trigger-color: #66FF66;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;