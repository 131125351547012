@import '../../styles/variables.less';

.login-container {
    form {
        .ant-btn {
            width: 100%;
        }

        >hr {
            height: 1px;
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
        }

        >span {
            text-align: center;
            display: block;
            margin-top: -21px;
            margin-bottom: 10px;
            z-index: 1;
            position: relative;

            p {
                font-size: 14px !important;
                padding: 0 10px;
                background-color: @layout-body-background;
                display: inline-block;
            }
        }
    }
}

@primary-color: #66FF66;@error-color: #FF6666;@highlight-color: #FF6666;@btn-primary-color: #1E2319;@font-family: Roboto Mono, monospace;@code-family: Roboto Mono, monospace;@font-size-base: 16px;@font-size-sm: 14px;@menu-bg: #1E2319;@layout-body-background: #101010;@layout-footer-background: rgba(30, 35, 25, 0.9);@layout-header-background: rgba(30, 35, 25, 0.9);@layout-trigger-color: #66FF66;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;