@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap');

@import './variables.less';

@import '~antd/dist/antd.less';

.page {
  padding-top: @layout-header-height;
  min-height: 100vh;

  @media screen and (min-width: @mobile-width) {
    &.auth {
      padding-left: 200px;
      transition: all 0.2s;

      &.collapsed {
        padding-left: 80px;
      }
    }
  }

  >.ant-layout-sider {
    margin-top: -64px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    z-index: 100;
    left: 0;
    height: 100vh;
    position: fixed;

    .ant-layout-sider-zero-width-trigger {
      top: 0;
      line-height: 55px;

      .anticon {
        font-size: 22px;
      }
    }

    @media screen and (max-width: @mobile-width - 1px) {
      position: fixed;
      left: 0;
      top: 0;
      margin-top: 0;
      height: 100vh;
      z-index: 110;
    }

    .logo-container {
      max-width: 200px;
      height: @layout-header-height;
      line-height: @layout-header-height;
      display: inline-block;
      text-align: center;

      img {
        max-width: 90%;
        height: 100%;
        margin-top: -5px;
      }
    }
  }

  .go-back-btn {
    padding: 4px 0;
  }

  .ant {
    &-layout {
      &-header {
        position: fixed;
        width: 100vw;
        top: 0;
        padding: 0 calc((100vw - @desktop-width)/2);
        z-index: 100;
        box-shadow: 0 -20px 40px #66FF66;

        @media screen and (max-width: @desktop-width) {
          padding: 0 40px;
        }

        &.auth {
          width: calc(100% - 200px);
          padding: 0 20px;
          right: 0;
          transition: all 0.2s;

          &.collapsed {
            width: 100%;

            @media screen and (min-width: @mobile-width) {
              width: calc(100% - 80px);
            }
          }
        }

        ul.ant-menu {
          display: block;
          float: right;
          clear: right;
          border: 0;

          .profile-menu {
            margin-right: 0;
            line-height: 55px;

            .anticon {
              padding: 0 14px;
              margin: 0;
              font-size: 22px;
            }
          }
        }

        .logo-container {
          max-width: 200px;
          height: 40%;
          display: inline-block;

          @media screen and (max-width: @mobile-width) {
            max-width: 50%;
          }

          img {
            max-width: 100%;
            height: 100%;
            margin-top: -3px;
          }
        }
      }

      &-content {
        position: relative;
        &.auth {
          //background-color: #F0F2F5;
          padding: 40px 0;
          @media screen and (max-width: @mobile-width) {
            padding: 20px 0;
          }
        }
      }

      &-has-sider {
        .ant-layout {
          &-content {
            @media screen and (min-width: @desktop-width) {
              padding: 40px 0 40px calc(50vw - @desktop-width/2 - 40px);
            }
          }

          &-sider {
            background-color: @layout-body-background;

            .helper {
              display: inline-block;
              height: 100%;
              vertical-align: middle;
            }

            img {
              width: 100%;
              vertical-align: middle;
            }

            @media screen and (max-width: @mobile-width) {
              display: none;
            }
          }
        }
      }
      
      &-footer {

        > div {
          > div:first-child {
            text-align: left;
            > a {
              font-size: 30px;
              margin-right: 10px;
            }
          }
          > div:last-child {
            text-align: right;
          }
        }
        
        padding: 10px calc((100vw - @desktop-width)/2);

        box-shadow: 0 20px 40px #66FF66;
        
        position: fixed;
        z-index: 1;
        bottom: 0;
        right: 0;
        left: 0;
        
        @media screen and (max-width: @desktop-width) {
          padding: 20px 40px;
        }
      }
    }
  }

  .content {

    &-title,
    &-body {
      padding: 0 40px 5px;
    }
  }
}

.profile-menu-popup {
  .anticon {
    margin: 0 0 1em;
  }
  .ant-btn {
    padding: 0;
  }
}

@primary-color: #66FF66;@error-color: #FF6666;@highlight-color: #FF6666;@btn-primary-color: #1E2319;@font-family: Roboto Mono, monospace;@code-family: Roboto Mono, monospace;@font-size-base: 16px;@font-size-sm: 14px;@menu-bg: #1E2319;@layout-body-background: #101010;@layout-footer-background: rgba(30, 35, 25, 0.9);@layout-header-background: rgba(30, 35, 25, 0.9);@layout-trigger-color: #66FF66;@layout-zero-trigger-height: 64px;@layout-zero-trigger-width: 70px;